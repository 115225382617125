body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: blueviolet; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #5B696Aff;;
  text-decoration: none; /* Remove underline or other default text decorations */
  /* Add any other styles you want */
}

a:hover {
  color: #8EAFBEff;
  text-decoration: none; /* Remove underline or other default text decorations */
  /* Add any other styles you want */
}


/* styles.css */
.my-input {
  margin-top: 8px;
  margin-bottom: 8px;
}

.supplier-section {
  padding: 20px;
  border: 5px solid #406079ff;
  border-radius: 5px;
  margin: 10px;
}
.first-section {
  padding-bottom: 20px;
}

/* color palette
/* CSS HEX 
--ivory: #FAF8EBff;
gray
--dim-gray: #5B696Aff;
dark blue
--paynes-gray: #406079ff;
light blue
--cadet-gray: #8EAFBEff;
--sage: #C4C5A6ff;

 CSS HSL 
--ivory: hsla(52, 60%, 95%, 1);
--dim-gray: hsla(184, 8%, 39%, 1);
--paynes-gray: hsla(206, 31%, 36%, 1);
--cadet-gray: hsla(199, 27%, 65%, 1);
--sage: hsla(62, 21%, 71%, 1);

 SCSS HEX 
$ivory: #FAF8EBff;
$dim-gray: #5B696Aff;
$paynes-gray: #406079ff;
$cadet-gray: #8EAFBEff;
$sage: #C4C5A6ff;

 SCSS HSL 
$ivory: hsla(52, 60%, 95%, 1);
$dim-gray: hsla(184, 8%, 39%, 1);
$paynes-gray: hsla(206, 31%, 36%, 1);
$cadet-gray: hsla(199, 27%, 65%, 1);
$sage: hsla(62, 21%, 71%, 1);

 SCSS RGB 
$ivory: rgba(250, 248, 235, 1);
$dim-gray: rgba(91, 105, 106, 1);
$paynes-gray: rgba(64, 96, 121, 1);
$cadet-gray: rgba(142, 175, 190, 1);
$sage: rgba(196, 197, 166, 1);

 SCSS Gradient 
$gradient-top: linear-gradient(0deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-right: linear-gradient(90deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-bottom: linear-gradient(180deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-left: linear-gradient(270deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-top-right: linear-gradient(45deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-bottom-right: linear-gradient(135deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-top-left: linear-gradient(225deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-bottom-left: linear-gradient(315deg, #FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
$gradient-radial: radial-gradient(#FAF8EBff, #5B696Aff, #406079ff, #8EAFBEff, #C4C5A6ff);
*/